body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.fade-enter * {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active * {
    opacity: 1;
    transition: opacity 450ms ease-in;
}

.unscroll {
    overflow: hidden;
}

body .olark-hidden~.olark-launch-button-wrapper {
    z-index: 99 !important;
}

@font-face {
    font-family: 'IntervogueSoft';
    src: url('./shop/assets/fonts/IntervogueSoft-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntervogueSoft';
    src: url('./shop/assets/fonts/IntervogueSoft-Med.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntervogueSoft';
    src: url('./shop/assets/fonts/IntervogueSoft-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}